import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp);
Vue.config.productionTip = false
Vue.use(Element, {
  size:'medium', 
})

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
