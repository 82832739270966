import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/index',
		component: () => import('@/views/index/index'),
		meta:{
			title:'化氏渔具'
		}
	},
	{
		path: '*',
		component: () => import('@/views/index/index'),
		meta:{
			title:'化氏渔具'
		}
	},
]

const router = new VueRouter({
	mode: 'history',
	base: "/",
	routes
})

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

export default router
